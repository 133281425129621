<template>
  <div>
    <v-row class="ma-0 border-bottom-light">
      <v-col cols="12" class="d-flex align-center">
        <span class="text-h5 font-weight-medium">Report Center</span>
      </v-col>
    </v-row>
    <v-row class="ma-0 d-flex justify-center">
      <v-col cols="11">
        <v-row class="ma-0">
          <v-col
            v-for="(report, i) in reportListCards"
            :key="i"
            class="pa-0"
            style="margin-right: 8px; margin-bottom: 8px"
          >
            <v-card
              flat
              :class="{
                'primary-card': selectedCard === report.name,
                'secondary-card': selectedCard !== report.name,
              }"
              style="min-width: 50px"
              @click="toggleSelection(report.name)"
            >
              <v-col cols="12" class="d-flex justify-center">
                <v-icon :color="report.color" size="40">
                  {{ report.icon }}
                </v-icon>
              </v-col>
              <v-col class="pt-0">
                <span
                  class="text-subtitle-2 font-weight-medium d-flex justify-center"
                >
                  {{ report.name }}
                </span>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card class="ma-3 rounded-lg" variant="outlined">
      <v-form
        v-if="selectedReportName"
        id="admin-report-form"
        ref="reportForm"
        v-model="isValid"
        name="admin-report-form"
      >
        <v-row class="ma-0">
          <v-col cols="6" lg="6">
            <span class="text-h6 primary-text pa-1">Report Details</span>
          </v-col>
          <v-col cols="6" lg="6" class="d-flex justify-end">
            <v-btn
              id="admin-report-submit"
              name="admin-report-submit"
              class="mr-2"
              color="primary"
              :disabled="!isValid"
              @click="submitForm()"
            >
              Submit
            </v-btn>
            <v-btn
              id="admin-report-reset"
              name="admin-report-reset"
              class="mr-2"
              @click="resetForm()"
            >
              Reset
            </v-btn>
          </v-col>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 pa-2 d-flex align-center">
          <v-col cols="12" lg="3" class="Form-Columns">
            <MultiSelect
              label="Select Report *"
              :items="reportList"
              itemText="display_name"
              itemValue="value"
              hide-details="auto"
              :rules="[(val) => !!val || 'Report is Required!']"
              v-model="filtersObject.report_type"
              :error-messages="formErrors.report_type"
              @input="
                delete formErrors.report_type;
                getReportDetail();
              "
            >
            </MultiSelect>
          </v-col>
          <v-col class="Form-Columns">
            <DateField
              :inputProps="{
                dense: true,
                label: 'Start Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'Date is Required!'],
                'error-messages': formErrors.start_date,
              }"
              required
              :disabled="!filtersObject.report_type"
              :max="today"
              v-model="filtersObject.start_date"
              @input="
                delete formErrors.start_date;
                checkDates();
              "
            />
          </v-col>

          <v-col
            v-if="filtersObject.report_type == 'Vehicle Cost Report'"
            class="Form-Columns"
          >
            <DateField
              :inputProps="{
                dense: true,
                label: 'End Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'End Date is Required!'],
                'error-messages': formErrors.end_date,
              }"
              required
              :disabled="!filtersObject.start_date"
              :min="filtersObject.start_date"
              :max="maxEndDate"
              v-model="filtersObject.end_date"
              @input="delete formErrors.end_date"
            />
          </v-col>
          <v-col class="Form-Columns" v-else>
            <DateField
              :inputProps="{
                dense: true,
                label: 'End Date *',
                'hide-details': 'auto',
                rules: [(val) => !!val || 'End Date is Required!'],
                'error-messages': formErrors.end_date,
              }"
              required
              :disabled="!filtersObject.start_date"
              :min="filtersObject.start_date"
              v-model="filtersObject.end_date"
              @input="delete formErrors.end_date"
            />
          </v-col>

          <v-col v-if="showJobType" class="Form-Columns">
            <MultiSelect
              label="Job Type "
              :items="allJobType"
              itemText="display_name"
              itemValue="value"
              hide-details="auto"
              v-model="filtersObject.job_type"
            />
          </v-col>

          <v-col
            v-if="
              filtersObject.report_type == 'Invoice List' ||
              filtersObject.report_type == 'Customer Invoice Detailed Report' ||
              filtersObject.report_type == 'Invoice Da Detail Report' ||
              filtersObject.report_type == 'DA Pending To Invoice Report' ||
              filtersObject.report_type ==
                'Customer Delivery Advice Summary Report' ||
              filtersObject.report_type == 'Payment Receipt Report'
            "
          >
            <MultiSelect
              label="Select Branch"
              clearable
              :items="allBranchist"
              v-model="filtersObject.branch"
              itemText="branch_name"
              itemValue="id"
              hide-details="auto"
              :returnObject="true"
            />
          </v-col>

          <v-col v-if="showStatus">
            <MultiSelect
              label="Status"
              :items="invoicestatusList"
              itemText="status_da"
              itemValue="id"
              hide-details="auto"
              v-model="filtersObject.invoice_da_status"
            />
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showDaListFields">
            <MultiSelect
              label="Status"
              :items="statusList"
              itemText="title"
              itemValue="id"
              hide-details="auto"
              v-model="filtersObject.da_status"
            />
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showDaListFields">
            <MultiSelect
              label="Payment Mode"
              :items="paymentTerms"
              hide-details="auto"
              itemText="display_name"
              itemValue="value"
              v-model="filtersObject.payment_term"
              :error-messages="formErrors.payment_term"
            ></MultiSelect>
          </v-col>

          <v-col v-if="showDrivers" class="Form-Columns">
            <MultiSelect
              label="Select Driver *"
              :items="allDriverList"
              itemText="driver_name"
              itemValue="id"
              :returnObject="true"
              :clearable="true"
              hide-details="auto"
              :rules="[(val) => !!val || 'Driver is Required!']"
              v-model="filtersObject.driver"
            />
          </v-col>

          <v-col v-if="showDriversNotMandatory" class="Form-Columns">
            <MultiSelect
              label="Select Driver "
              :items="allDriverList"
              itemText="driver_name"
              itemValue="id"
              :returnObject="true"
              :clearable="true"
              hide-details="auto"
              v-model="filtersObject.driver"
            />
          </v-col>

          <v-col
            class="Form-Columns-Dense"
            v-if="
              showDepot ||
              this.filtersObject.report_type == 'Vehicle Summary Report' ||
              this.filtersObject.report_type == 'Driver Bonus Report' ||
              this.filtersObject.report_type ==
                'Delivery Advice Summary Report' ||
              this.filtersObject.report_tye ===
                'Customer Delivery Advice Summary Report' ||
              this.filtersObject.report_type ==
                'Sub Hire Jobs Summary Report' ||
              this.filtersObject.report_type == 'Sub Hire Jobs Detail Report' ||
              this.filtersObject.report_type ==
                'Delivery Advice Time Stamp Report' ||
              this.filtersObject.report_type == 'Driver Summary Report' ||
              this.filtersObject.report_type ==
                'Driver Bonus Detailed Report' ||
              this.filtersObject.report_type == 'SAP Driver Bonus Report' ||
              this.filtersObject.report_type == 'Driver Bonus Summary Report' ||
              this.filtersObject.report_type ==
                'Sub Hire Jobs Completed Report' ||
              this.filtersObject.report_type == 'Vehicle Cost Report'
            "
          >
            <MultiSelect
              label="Select Depot"
              :items="allDepotList"
              itemText="depot_name"
              itemValue="id"
              hide-details="auto"
              :returnObject="true"
              v-model="filtersObject.depot"
            ></MultiSelect>
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showPaymentModes">
            <MultiSelect
              label="Select Payment Mode"
              :items="allpaymentModes"
              itemText="display_name"
              itemValue="value"
              hide-details="auto"
              v-model="filtersObject.payment_mode"
            ></MultiSelect>
          </v-col>

          <v-col v-if="showCustomers" class="Form-Columns">
            <MultiSelect
              :label="customeLabel"
              :items="allCustomers"
              itemText="company_name"
              itemValue="id"
              :returnObject="true"
              :clearable="true"
              hide-details="auto"
              :rules="customerRules"
              v-model="filtersObject.customer"
              :error-messages="formErrors.customer"
              @input="delete formErrors.customer"
            >
            </MultiSelect>
          </v-col>

          <v-col v-if="showTransporters" class="Form-Columns">
            <MultiSelect
              :label="transporterLabel"
              :items="allTransporters"
              itemText="company_name"
              itemValue="id"
              :returnObject="true"
              :clearable="true"
              hide-details="auto"
              v-model="filtersObject.transporter"
            >
            </MultiSelect>
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showSubHireStatus">
            <MultiSelect
              label="Status"
              :items="subhireStatusList"
              itemText="title"
              itemValue="id"
              hide-details="auto"
              v-model="filtersObject.sub_hire_status"
            />
          </v-col>

          <v-col
            class="Form-Columns"
            v-if="
              showSubHireStatus &&
              filtersObject.sub_hire_status &&
              filtersObject.sub_hire_status != 'Pending'
            "
          >
            <DateField
              :inputProps="{
                dense: true,
                label: 'Status Start Date',
                'hide-details': 'auto',
              }"
              :disabled="!filtersObject.report_type"
              :min="filtersObject.start_date"
              :max="filtersObject.end_date"
              v-model="filtersObject.status_start_date"
              @input="
                delete formErrors.status_start_date;
                checkDates();
              "
            />
          </v-col>

          <v-col
            class="Form-Columns"
            v-if="
              showSubHireStatus &&
              filtersObject.sub_hire_status &&
              filtersObject.sub_hire_status != 'Pending'
            "
          >
            <DateField
              :inputProps="{
                dense: true,
                label: 'Status End Date',
                'hide-details': 'auto',
              }"
              :min="filtersObject.status_start_date"
              :max="filtersObject.end_date"
              v-model="filtersObject.status_end_date"
              @input="delete formErrors.status_end_date"
            />
          </v-col>

          <v-col v-if="showVehicles" class="Form-Columns">
            <MultiSelect
              :label="`Select Vehicle`"
              :items="allVehicleList"
              itemText="inventory_number"
              itemValue="id"
              :returnObject="true"
              :clearable="true"
              hide-details="auto"
              v-model="filtersObject.vehicle"
            />
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showLocation">
            <MultiSelect
              hide-details="auto"
              label="Source Location"
              :items="sourceList"
              itemText="location_code"
              itemValue="id"
              :item-disabled="
                (item) => {
                  return item == filtersObject.mid_location;
                }
              "
              :returnObject="true"
              v-model="filtersObject.source_location"
              :error-messages="formErrors.source_location"
              @change="delete formErrors.source_location"
            />
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showLocation">
            <MultiSelect
              hide-details="auto"
              label="Mid Location"
              :items="midList"
              itemText="location_code"
              itemValue="id"
              :returnObject="true"
              :item-disabled="
                (item) => {
                  return (
                    item == filtersObject.source_location ||
                    item == filtersObject.destination_location
                  );
                }
              "
              v-model="filtersObject.mid_location"
              :error-messages="formErrors.mid_location"
              @change="delete formErrors.mid_location"
            />
          </v-col>

          <v-col class="Form-Columns-Dense" v-if="showLocation">
            <MultiSelect
              hide-details="auto"
              label="Destination Location"
              :items="destinationList"
              itemText="location_code"
              itemValue="id"
              :returnObject="true"
              :item-disabled="
                (item) => {
                  return item == filtersObject.mid_location;
                }
              "
              :clearable="true"
              v-model="filtersObject.destination_location"
              :error-messages="formErrors.destination_location"
              @change="delete formErrors.destination_location"
            />
          </v-col>
          <v-col
            class="Form-Columns"
            v-if="filtersObject.report_type == 'Invoice List'"
          >
            <MultiSelect
              label="Select Invoice Type"
              clearable
              :items="allInvoiceType"
              v-model="filtersObject.invoice_type"
              itemText="company_name"
              itemValue="id"
              hide-details="auto"
            />
          </v-col>
          <v-col class="Form-Columns" v-if="showInvoiceNumber">
            <InputField
              type="text"
              label="Invoice Number From"
              hide-details="auto"
              v-model="filtersObject.invoice_no_from"
            />
          </v-col>

          <v-col class="Form-Columns" v-if="showInvoiceNumber">
            <InputField
              type="text"
              label="Invoice Number To"
              hide-details="auto"
              v-model="filtersObject.invoice_no_to"
            />
          </v-col>
          <v-col class="Form-Columns" v-if="showBOE">
            <InputField type="text" label="BOE" v-model="filtersObject.boe" />
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-card class="rounded-lg mt-2 ml-2 mr-2" variant="outlined" elevation="2">
      <v-divider></v-divider>
      <v-row class="ma-0">
        <v-col cols="2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCustomers()"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            class="mr-2"
            depressed
            color="primary"
            @click="getReportList()"
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="pa-0 mt-5 pr-3 pl-2 pb-3 d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
        <v-col cols="12">
          <AgGridVue
            style="width: 100%; height: calc(60vh - 145px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allReportData"
          ></AgGridVue>
        </v-col>
      </v-row>
    </v-card>

    <AdminReportInfo
      v-model="showAdminReportInfo"
      :showReportDialogInfo="showReportDialogInfo"
    />
    <ReportsCenterDetail
      v-model="showReportsDetailView"
      :reportObjId="reportId"
      :reportType="reportTypeName"
    />
  </div>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
// import ReportStatus from "@/components/AgGridComponents/Buttons/admin_company/ReportStatus.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import ReportDownloadButton from "@/components/AgGridComponents/ReportDownloadButton.vue";
import AdminReportInfo from "@/components/ModuleBased/dialogs/AdminReportInfo.vue";
import ReportsCenterDetail from "@/components/ModuleBased/details/ReportsCenterDetail";
import StatusChip from "@/components/AgGridComponents/General/StatusChip.vue";
import ProgressBar from "@/components/AgGridComponents/General/ProgressBar.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import { ReportType } from "@/utils/choices";

export default {
  components: {
    ListComponent,
    AgGridVue,
    DateField,
    Pagination,
    ReportDownloadButton,
    AdminReportInfo,
    ReportsCenterDetail,
    ProgressBar,
    StatusChip,
    MultiSelect,

    // ReportStatus,
  },
  data() {
    return {
      allReports: ReportType,
      filters: {},
      permissions: null,
      showFilterDialog: false,
      showReportDialogInfo: {},
      emission_report: "",
      showAdminReportInfo: false,
      showVehicles: false,
      showReportsDetailView: false,
      showDepot: false,
      paymentTerms: ["Cash", "Credit"],
      subhireStatusList: [
        "Pending",
        "Approved",
        "Invoice Updated",
        "Completed",
      ],
      allJobType: [],
      allInvoiceType: ["General", "Bill Of Entry"],
      reportListCards: [
        {
          name: "Finance Reports",
          icon: "mdi-finance",
          color: "primary",
        },
        {
          name: "Delivery Advice Reports",
          icon: "mdi-truck-delivery",
          color: "red",
        },
        {
          name: "Trip Report",
          icon: "mdi-car",
          color: "orange",
        },
        {
          name: "Vehicle Reports",
          icon: "mdi-car-pickup",
          color: "yellow",
        },
        {
          name: "Driver Reports",
          icon: "mdi-clipboard-account",
          color: "green",
        },
        {
          name: "Driver Bonus Report",
          icon: "mdi-clipboard-account",
          color: "blue",
        },
        {
          name: "Sub Hire Reports",
          icon: "mdi-clipboard-text",
          color: "primary",
        },
        {
          name: "Other Reports",
          icon: "mdi-chart-box",
          color: "purple",
        },
      ],
      headers: [
        {
          headerName: "Report Id",
          field: "report_id",
          minWidth: 70,
        },
        {
          headerName: "Report Type",
          sortable: true,
          field: "report_type",
          minWidth: 100,
        },

        {
          headerName: "Progress",
          field: "progress",
          cellRenderer: "ProgressBar",
          cellRendererParams: (params) => {
            return {
              progress: params.value,
            };
          },
          sortable: true,
          minWidth: 200,
        },

        {
          headerName: "Status",
          field: "status",
          minWidth: 70,
          cellRenderer: "StatusChip",
          cellRendererParams: (params) => {
            return {
              status: params.value,
            };
          },
        },

        {
          headerName: "Total Records",
          field: "total_records",
          minWidth: 70,
        },
        {
          headerName: "Requested By",
          sortable: true,
          field: "requested_user_name",
          minWidth: 70,
        },

        {
          headerName: "Created",
          field: "created",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "ReportDownloadButton",
          pinned: "right",
          minWidth: 50,
        },
      ],

      driverList: [],
      allReportData: [],
      drivers: null,
      trailerList: [],
      trailers: null,
      sourceList: [],
      midList: [],
      destinationList: [],

      created_by: null,
      pageNo: 1,
      itemsPerPage: 10,
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      customers: [],
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      completedDateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      showDriversNotMandatory: false,
      showJobType: false,
      showLocation: false,
      allReports: [],
      normalUserList: [],
      allCustomerList: [],
      allCustomers: [],

      filtersObject: {},
      reportInfo: {},
      totalItems: 100,
      today: new Date().toISOString().slice(0, 16),
      isValid: true,
      formErrors: {},
      reportList: [],
      allDepotList: [],
      trailerTypeCategoryList: [],

      reportlistData: [],
      branchList: [],

      daReportList: [],
      financeReportList: [],
      vehicleReportList: [],
      driverReportList: [],
      driverBonusReportList: [],
      subHireReportList: [],
      otherReportList: [],
      tripReportList: [],
      showBOE: false,
      showCustomers: false,
      showTransporters: false,

      reportList: [],
      showStatus: false,
      invoicestatusList: ["Pending", "Accepted"],

      branchIDs: [],

      showDaListFields: false,
      showSubHireStatus: false,
      showPaymentModes: false,
      showInvoiceNumber: false,
      vehicleList: [],
      allDriverList: [],
      allVehicleList: [],
      reportId: null,
      vehicles: null,
      showReportInfo: false,
      showReportDetail: false,
      reportTypeName: "",

      selectedReportCategory: null,
      select: false,
      showDrivers: false,
      selectedReportName: null,
      selectedCard: null,
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      if (obj) {
        return JSON.parse(obj);
      }
      return null;
    },
  },
  mounted() {
    this.getReportList();
    this.getReportOptions();
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("reports");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },
  created() {
    this.$bus.$on("socketMessageReceived", (data) => {
      this.onMessage({ data: data });
    });
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case "Success":
          return "green";
        case "In Progress":
          return "yellow";

        default:
          return "gray";
      }
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getReportList();
    },
    prevPage() {
      this.pageNo--;
      this.getReportList();
    },
    nextPage() {
      this.pageNo++;
      this.getReportList();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    // resetData() {
    //   this.reportList = [];
    //   this.resetForm();
    // },
    resetFilters() {
      this.filters = {
        status: [],
      };
      this.itemsPerPageChanged(50);
    },
    resetForm() {
      this.reportList = [];
      if (this.$refs.reportForm) {
        this.$refs.reportForm.resetValidation();
        this.$refs.reportForm.reset();
      }
      this.filtersObject = {};
      this.filtersObject = {
        ...this.filtersObject,
      };
      this.selectedReportCategory = null;
      this.branchIDs = [];
      this.customers = [];
      this.created_by = null;

      this.formErrors = {};
    },

    toggleSelection(val) {
      this.select = true;

      this.selectedReportName = val;

      if (val === this.selectedReportCategory) {
        this.selectedReportCategory = null;
        this.selectedCard = val;
        this.filtersObject.report_type = null;
      } else {
        this.selectedReportCategory = val;
        this.selectedCard = val;
        this.filtersObject.report_type = null;
      }

      if (this.selectedReportCategory == "Finance Reports") {
        this.reportList = this.financeReportList;
      }
      if (this.selectedReportCategory == "Delivery Advice Reports") {
        this.reportList = this.daReportList;
      }
      if (this.selectedReportCategory == "Vehicle Reports") {
        this.reportList = this.vehicleReportList;
      }
      if (this.selectedReportCategory == "Driver Reports") {
        this.reportList = this.driverReportList;
      }
      if (this.selectedReportCategory == "Driver Bonus Reports") {
        this.reportList = this.driverBonusReportList;
      }
      if (this.selectedReportCategory == "Sub Hire Reports") {
        this.reportList = this.subHireReportList;
      }
      if (this.selectedReportCategory == "Other Reports") {
        this.reportList = this.otherReportList;
      }
      if (this.selectedReportCategory == "Trip Report") {
        this.reportList = this.tripReportList;
      }

      const reportCategories = [
        "Live Operations Report",
        "Trip Report",
        "Driver Trip Bonus Report",
      ];

      if (reportCategories.includes(this.selectedReportCategory)) {
        this.getDriverList();
      }

      //   this.resetData();
    },
    getReportOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.reportcenter
        .getReportOptions()
        .then((res) => {
          this.allReports = res.data.actions.POST.report_type.choices;

          this.allReports.forEach((element) => {
            if (
              [
                "Customer Delivery Advice Summary Report",
                "Delivery Advice Summary Report",
                "Delivery Advice Time Stamp Report",
                "Delivery Advice List Report",
                // "DA Cost Report",
                "DA Profit Summary Report",
                "DA Profit Detail Report",
                "Hazard Delivery Advice Report",
              ].includes(element.value)
            ) {
              this.daReportList.push(element);
            }
            if (
              [
                "Invoice Da Detail Report",
                "Customer Invoice Detailed Report",
                "Invoice List",
                "DA Pending To Invoice Report",
                "Payment Receipt Report",
                "Credit Note Detailed Report",
              ].includes(element.value)
            ) {
              this.financeReportList.push(element);
              this.getBranchList();
            }
            if (
              [
                "Vehicle Summary Report",
                "Vehicle Report",
                "Vehicle Cost Report",
              ].includes(element.value)
            ) {
              this.vehicleReportList.push(element);
            }
            if (
              ["Driver Summary Report", "Driver Report"].includes(element.value)
            ) {
              this.driverReportList.push(element);
            }
            if (
              [
                "Driver Bonus Report",
                "Driver Bonus Detailed Report",
                "Driver Bonus Summary Report",
                "SAP Driver Bonus Report",
              ].includes(element.value)
            ) {
              this.driverBonusReportList.push(element);
            }
            if (
              [
                "Sub Hire Jobs Summary Report",
                "Sub Hire Jobs Detail Report",
                "Sub Hire Jobs Completed Report",
              ].includes(element.value)
            ) {
              this.subHireReportList.push(element);
            }
            if (
              [
                "Defective Checklist Report",
                "Trip CheckList Log",
                "Location Summary Report",
              ].includes(element.value)
            ) {
              this.otherReportList.push(element);
            }
            if (["Trip Report"].includes(element.value)) {
              this.tripReportList.push(element);
            }
          });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    viewReports(id, typeOfReport) {
      this.reportId = id;
      this.reportTypeName = typeOfReport;
      this.showReportsDetailView = true;
    },

    viewReportsInfo(data) {
      this.showAdminReportInfo = true;
      this.showReportDialogInfo = data;
    },

    download(response, fileName) {
      var a = document.createElement("a");
      let url = URL.createObjectURL(response);
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    },
    async downloadReport(data) {
      this.$bus.$emit("showLoader", true);
      await this.$api.reportcenter
        .getReportBlobObject(data.id)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download =
            data.report_type + "_" + data.report_id + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.filtersObject,
      };

      if (
        this.filtersObject.customer &&
        this.filtersObject.customer.company_name &&
        typeof this.filtersObject.customer === "object"
      ) {
        data.customer_name = this.filtersObject.customer.company_name;
      }
      if (
        this.filtersObject.customer &&
        this.filtersObject.customer.id &&
        typeof this.filtersObject.customer === "object"
      ) {
        data.customer = this.filtersObject.customer.id;
      }
      //transporter
      if (
        this.filtersObject.transporter &&
        this.filtersObject.transporter.company_name &&
        typeof this.filtersObject.transporter === "object"
      ) {
        data.transporter_name = this.filtersObject.transporter.company_name;
      }
      if (
        this.filtersObject.transporter &&
        this.filtersObject.transporter.id &&
        typeof this.filtersObject.transporter === "object"
      ) {
        data.transporter = this.filtersObject.transporter.id;
      }

      if (
        this.filtersObject.branch &&
        this.filtersObject.branch.branch_name &&
        typeof this.filtersObject.branch === "object"
      ) {
        data.branch_name = this.filtersObject.branch.branch_name;
      }

      if (
        this.filtersObject.invoice_da_status &&
        typeof this.filtersObject.invoice_da_status === "object"
      ) {
        data.status_da = this.filtersObject.status_da;
      }

      if (
        this.filtersObject.branch &&
        this.filtersObject.branch.id &&
        typeof this.filtersObject.branch === "object"
      ) {
        data.branch = this.filtersObject.branch.id;
      }

      if (
        this.filtersObject.vehicle &&
        typeof this.filtersObject.vehicle === "object"
      ) {
        if (this.filtersObject.vehicle.id) {
          data.vehicle = this.filtersObject.vehicle.id;
        }
        if (this.filtersObject.vehicle.inventory_number) {
          data.vehicle_name = this.filtersObject.vehicle.inventory_number;
        }
      }

      if (
        this.filtersObject.driver &&
        typeof this.filtersObject.driver === "object"
      ) {
        if (this.filtersObject.driver.id) {
          data.driver = this.filtersObject.driver.id;
        }
        if (this.filtersObject.driver.driver_name) {
          data.driver_name = this.filtersObject.driver.driver_name;
        }
      }
      if (
        this.filtersObject.source_location &&
        typeof this.filtersObject.source_location === "object"
      ) {
        if (this.filtersObject.source_location.id) {
          data.source_location = this.filtersObject.source_location.id;
        }
        if (this.filtersObject.source_location.title) {
          data.source_location_name = this.filtersObject.source_location.title;
        }
      }
      if (
        this.filtersObject.mid_location &&
        typeof this.filtersObject.mid_location === "object"
      ) {
        if (this.filtersObject.mid_location.id) {
          data.mid_location = this.filtersObject.mid_location.id;
        }
        if (this.filtersObject.mid_location.title) {
          data.mid_location_name = this.filtersObject.mid_location.title;
        }
      }
      if (
        this.filtersObject.destination_location &&
        typeof this.filtersObject.destination_location === "object"
      ) {
        if (this.filtersObject.destination_location.id) {
          data.destination_location =
            this.filtersObject.destination_location.id;
        }
        if (this.filtersObject.destination_location.title) {
          data.destination_location_name =
            this.filtersObject.destination_location.title;
        }
      }
      if (
        this.filtersObject.depot &&
        typeof this.filtersObject.depot === "object"
      ) {
        if (this.filtersObject.depot.id) {
          data.depot = this.filtersObject.depot.id;
        }
        if (this.filtersObject.depot.depot_name) {
          data.depot_name = this.filtersObject.depot.depot_name;
        }
      }
      this.$api.reportcenter
        .getReportData(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.getReportList();
          this.resetForm();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Request is not accepted",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.midList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getVehicleList() {
      this.$bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleSelectList({
          limit: "all",
        })
        .then((res) => {
          this.allVehicleList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getReportDetail() {
      if (
        this.filtersObject &&
        [
          "Customer Invoice Detailed Report",
          "DA Pending To Invoice Report",
        ].includes(this.filtersObject.report_type)
      ) {
        this.customeLabel = "Select Customer";
      } else {
        this.customerRules = [];
        this.customeLabel = "Select Customer";
      }
      // for customer
      if (
        this.filtersObject &&
        [
          "Invoice Da Detail Report",
          "Customer Delivery Advice Summary Report",
          "DA Pending To Invoice Report",
          "Customer Invoice Detailed Report",
          "Customer Delivery Advice Summary Report",
          "Invoice List",
          "Delivery Advice List Report",
          "Delivery Advice Summary Report",
          // "DA Cost Report",
          "DA Profit Summary Report",
          "DA Profit Detail Report",
          "Payment Receipt Report",
          "Sub Hire Jobs Detail Report",
          "Sub Hire Jobs Summary Report",
          "Sub Hire Jobs Completed Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.allCustomers.length) {
          this.getCustomerList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }

        this.showCustomers = true;
        if (this.filtersObject.report_type == "Payment Receipt Report") {
          if (!this.allpaymentModes.length) {
            this.getPaymentReceiptOptions();
          }
          this.showPaymentModes = true;
        } else {
          this.showPaymentModes = false;
        }
      } else {
        this.showPaymentModes = false;
        this.showCustomers = false;
      }
      // for invoice status
      if (
        this.filtersObject &&
        ["Invoice Da Detail Report"].includes(this.filtersObject.report_type)
      ) {
        if (!this.allCustomers.length) {
          this.getCustomerList();
        }
        this.showStatus = true;
      } else {
        this.showStatus = false;
      }

      //for sub hire status
      if (
        this.filtersObject &&
        ["Sub Hire Jobs Detail Report"].includes(this.filtersObject.report_type)
      ) {
        if (!this.allTransporters.length) {
          this.getTransporterList();
        }
        this.showSubHireStatus = true;
      } else {
        this.showSubHireStatus = false;
      }

      //for customer and transporter
      if (
        this.filtersObject &&
        [
          "Sub Hire Jobs Detail Report",
          "Sub Hire Jobs Summary Report",
          "Sub Hire Jobs Completed Report",
          // "DA Cost Report",
          "DA Profit Summary Report",
          "DA Profit Detail Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.allTransporters.length) {
          this.getTransporterList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }
        this.showTransporters = true;
      } else {
        this.showTransporters = false;
      }

      //for invoice list
      if (
        this.filtersObject &&
        ["Invoice List", "Delivery Advice List Report"].includes(
          this.filtersObject.report_type
        )
      ) {
        if (!this.allJobType.length) {
          this.getJobTypeOptions();
        }
        this.showJobType = true;
      } else {
        this.showJobType = false;
      }

      // for driver
      if (
        this.filtersObject &&
        this.filtersObject.report_type == "Driver Report"
      ) {
        if (!this.allDriverList.length) {
          //   this.getDriverList();
        }
        this.showDrivers = true;
      } else {
        this.showDrivers = false;
      }
      // for not mandatory drivers
      if (
        (this.filtersObject &&
          this.filtersObject.report_type == "Driver Summary Report") ||
        this.filtersObject.report_type == "Driver Bonus Report" ||
        this.filtersObject.report_type == "Driver Bonus Summary Report" ||
        this.filtersObject.report_type == "Driver Bonus Detailed Report"
      ) {
        if (!this.allDriverList.length) {
          this.getDriverList();
        }
        this.showDriversNotMandatory = true;
      } else {
        this.showDriversNotMandatory = false;
      }
      // for invoice list
      if (
        this.filtersObject &&
        ["Invoice List"].includes(this.filtersObject.report_type)
      ) {
        this.showInvoiceNumber = true;
      } else {
        this.showInvoiceNumber = false;
      }
      // for vehicle
      if (
        (this.filtersObject &&
          (this.filtersObject.report_type == "Driver Summary Report" ||
            this.filtersObject.report_type == "Vehicle Report")) ||
        this.filtersObject.report_type == "Driver Bonus Report" ||
        this.filtersObject.report_type == "Vehicle Summary Report" ||
        this.filtersObject.report_type == "Driver Report" ||
        this.filtersObject.report_type == "Vehicle Cost Report"
      ) {
        if (!this.allVehicleList.length) {
          this.getVehicleList();
        }
        if (!this.allDepotList.length) {
          this.getDepotList();
        }

        this.showVehicles = true;
      } else {
        this.showVehicles = false;
      }

      //for vehicle and driver
      if (
        this.filtersObject &&
        (this.filtersObject.report_type == "Driver Report" ||
          this.filtersObject.report_type == "Vehicle Report")
      ) {
        this.showLegDate = true;
      } else {
        this.showLegDate = false;
      }

      //for driver list and da list report
      if (
        this.filtersObject &&
        [
          "Driver Report",
          "Delivery Advice List Report",
          "Vehicle Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.sourceList.length) {
          this.getLocationList();
        }
        this.showLocation = true;
      } else {
        this.showLocation = false;
      }
      //da list report
      if (
        this.filtersObject &&
        ["Delivery Advice List Report"].includes(this.filtersObject.report_type)
      ) {
        this.showDaListFields = true;
        this.showBOE = true;
      } else {
        this.showDaListFields = false;
        this.showBOE = false;
      }

      if (
        this.filtersObject &&
        [
          "Driver Report",
          "Delivery Advice List Report",
          "Customer Delivery Advice Summary Report",
          "Vehicle Report",
          "Hazard Delivery Advice Report",
          "Delivery Advice Time Stamp Report",
          "Driver Summary Report",
          "Driver Bonus Detailed Report",
          "SAP Driver Bonus Report",
          "Driver Bonus Summary Report",
          "Sub Hire Jobs Completed Report",
        ].includes(this.filtersObject.report_type)
      ) {
        if (!this.sourceList.length) {
          this.getDepotList();
        }

        this.showDepot = true;
      } else {
        this.showDepot = false;
      }
    },
    getDriverList() {
      this.$bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverSelectList({
          limit: "all",
        })
        .then((res) => {
          this.allDriverList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getPaymentReceiptOptions() {
      return this.$api.paymentReceipt
        .getPaymentReceiptOptions()
        .then((res) => {
          this.allpaymentModes = res.data.actions.POST.payment_mode.choices;
          this.showPaymentModes = true;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporters = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    checkDates() {
      if (this.filtersObject.start_date) {
        let currentDate = new Date(this.filtersObject.start_date);
        let endDate = currentDate;
        endDate.setDate(currentDate.getDate() + 5);
        this.lastEndDate = endDate.toISOString().slice(0, 10);
      } else {
        this.filtersObject.end_date = null;
      }
    },

    getNormalUserList(params = {}) {
      this.$bus.emit("showLoader", true);
      params = {
        company: this.currentProfile.company,
        limit: "all",
        ...params,
      };
      this.$api.user
        .getUserSelect(params)
        .then((res) => {
          this.$bus.emit("showLoader", false);
          this.normalUserList = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data.error;
          if (err && err.data) {
            const showToast = (message) => {
              this.$bus.emit("showToastMessage", {
                message,
                color: "error",
                hasActions: true,
              });
            };

            if (err.data.message) {
              showToast(err.data.message);
            } else if (
              err.data.error &&
              err.data.error.non_field_errors &&
              err.data.error.non_field_errors.length
            ) {
              showToast(err.data.error.non_field_errors.join(", "));
            } else {
              showToast("Fetch User List Failed");
            }
          }
          this.$bus.emit("showLoader", false);
        });
    },
    getJobTypeOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.bmr
        .getBMROptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.showJobType = true;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allCustomers = res.data;
          this.showCustomers = true;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.depot
        .getDepotSelectList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allDepotList = res.data;
          // if (res && res.data && res.data.length) {
          //   this.directDaDetails.depot = res.data[0].id;
          // }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getBranchList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchist = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getReportList(params = {}, headers = {}, responseType = "json") {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...this.filters,
        ...params,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.reportcenter
        .getReportList(params, headers, responseType)
        .then((res) => {
          if (Object.keys(headers).length) {
            let fileName = `getReportList${this.filters.start_date}_TO_${this.filters.end_date}`;
            this.download(res.data, fileName);
          } else {
            this.allReportData = res.data;

            this.totalItems = res.count;
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },
    onMessage(ev) {
      if (ev && ev.data) {
        const data =
          typeof ev.data == typeof "" ? JSON.parse(ev.data) : ev.data;
        if (data.type && ["user_report_notification"].indexOf(data.type) > -1) {
          this.getReportList();
        }
      }
    },
  },
};
</script>
<style>
.List-Height {
  width: 100%;
  height: calc(60vh - 170px) !important;
}
.primary-card {
  background-color: #f4f1ee !important;
}

.secondary-card {
  background-color: #dedcd7 !important;
}
</style>
